const YearCompany = [
  {
    value:0,
    text:"0-0 years"
  },
  {
    value:1,
    text:"0-1 years"
},
{
    value:2,
    text:"2-3 years"
},
{
    value:4,
    text:"4-5 years"
},
{
    value:6,
    text:"6-7 years"
},
{
    value:8,
    text:"8-10 years"
},
{
    value:13,
    text:"11-15 years"
},{
    value:18,
    text:"16-20 years"
},
{
    value:20,
    text:"20+ years"
}
];
let Years = [];
const currentYear = new Date().getFullYear();
for (let year = 2001; year <= currentYear; year++) {
  Years.push(year);
}
const CurrentYears = Years;
const LearnProgramList = [
  "Online advertisement - Google",
  "Search engine (e.g., Google, Bing)",
  "Social media (e.g., Facebook, LinkedIn, Twitter)",
  "Word of mouth (e.g., from a friend, family member, colleague)",
  "ISB website",
  "Email from ISB",
  "Referred by a past / current student",
  "Referred by your employer",
  "Referred by someone from ISB",
];
const InfluencerList = [
  "Curriculum and courses offered",
  "Recommended by a colleague / friend",
  "Networking opportunities",
  "Availability of flexible learning",
  "Alumni Network",
  "Other",
];

const Industries = [
   "Agriculture" ,
 "Automobiles" ,
"Banking and finance" ,
  "Cement" ,
 "Chemicals" ,
   "Construction" ,
  "Education" ,
  "FMCG (fast-moving consumer goods)" ,
  "Healthcare" ,"Hospitality and tourism",
  "Information technology (IT) and software",
  "Logistics and transportation",
  "Media and entertainment",
  "Petroleum and natural gas",
  "Retail",
  "Steel",
  "Telecommunications",
  "Textiles",
  "Other"
];
export  {YearCompany,Industries,InfluencerList,LearnProgramList,CurrentYears} ;
