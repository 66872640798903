import { React,useState, useEffect,  } from "react";
import Nav from 'react-bootstrap/Nav';
import './LandNavbar.css';
import {base_url,random_number,storefront_url,api_error_message,program_management_role,land_nave_storefront_url,crypto_secret_key,admin_role} from  '../../utilities';
import { useHistory} from "react-router-dom";
import { Button } from 'react-bootstrap';
import axios from "../../axios-interceptor";
import Badge from 'react-bootstrap/Badge';
import Notification from './Notification';
import CryptoJS from 'crypto-js'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from 'react-bootstrap/Modal';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PmNotification from "./PmNotifications";


function LandNavbar() {
  // let {isLoggedIn,onLogout}=props
  const location = useLocation();
  const currentPath = location.pathname;
  const history = useHistory();
  let [user_id,setUserId] = useState('')
  const [showModal, setModalShow] = useState(false);
  let role =localStorage.getItem('role')?localStorage.getItem('role') :'' ;
  let bytes = CryptoJS.AES.decrypt(role, crypto_secret_key);
  let decrypted_role = bytes?.toString(CryptoJS.enc.Utf8);

  if(localStorage.getItem('User_Accesstoken') && localStorage.getItem('isLoggedIn')&& !localStorage.getItem('login_tracking')){
      axios.post(base_url+"login_tracking",{
        "user_id":  localStorage.getItem('user_id'), "is_login": 1
      }).then(res=>{
        localStorage.setItem('login_tracking',true)
      }).catch(err=>{
        console.log(err);
        if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/')
          window.location.reload();
        }else{
          toast.warn(api_error_message,{toastId:random_number});
        }
      })
      // localStorage.setItem('login','success');
      // localStorage.setItem('audit_user',false)
      // localStorage.setItem('user_id',user.sub)
      // if(localStorage.getItem('prev_url').indexOf('onlineapplication') > -1){
      
      //   setTimeout(()=>{
        
      //       history.push('/onlineapplication')
      //   },200)
      //   }else if(localStorage.getItem('prev_url').indexOf(localStorage.getItem('prev_url').split(window.location.host)[1]) > -1){
      //     let url = localStorage.getItem('prev_url')
      //     history.push(url.split(window.location.host)[1])
           
      //   }else{
      //     localStorage.removeItem('prev_url',window.location.href);
      //   }
        //  For temporary purpose we are commenting the below code
   /* }
     else{
      history.push('/emailverification')
    } */
  }
  const clearLocalstroageAndReload=()=>
  {
    localStorage.clear()
    sessionStorage.clear()
    // onLogout()
  }
  const signOut = async () => {
    try {
       handleClose()
      if(localStorage.getItem('Admin_Acesstoken'))
      {
        adminLogout()
      }
      else if(localStorage.getItem('isb_user'))
      {
        logoutTracking(false)
        isbUserLogout()
      }else if(localStorage.getItem('User_Accesstoken') && localStorage.getItem('isLoggedIn')){
        userLogout()
      }else if(localStorage.getItem('throw_err')){
        throw new Error('Something went wrong!');
      }
      else{
        logoutTracking(false)
        let keys = Object.keys(localStorage);
        let i = keys.length;
    while ( i-- ) {
         localStorage.removeItem(keys[i])
    }
   
      }
    
    } catch (error) {
      console.log('error signing out: ', error);
        localStorage.clear();
        history.push('/')
        window.location.reload();
        toast.warn(api_error_message,{toastId:random_number});
    }
  }
  const userLogout=()=>{
    
   axios.post(base_url+"isb/user/logout",{
     "auth_refresh_token":localStorage.getItem('User_Refreshtoken')
      })
       .then(res=>{
        logoutTracking(true)
       })
       .catch(err=>{
        console.log(err);
        if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/')
          window.location.reload();
        }else{
          toast.warn(api_error_message,{toastId:random_number});
        }
      })
  }
  // const adminLogin =  () => {
  //   history.push('/adminlogin')
  // }


  const adminLogout=()=>{
    axios.post(base_url + "admin/logout",{"refresh_token":localStorage.getItem('Admin_refreshtoken')})
    .then((res)=>{
      clearLocalstroageAndReload()
      history.push('/adminlogin')
     window.location.reload()
    })
    .catch((err)=>{
      if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/adminlogin')
        window.location.reload();
      }else{
        toast.warn(api_error_message,{toastId:random_number});
      }
    })

  }

// Function to handle redirection and reload
function redirectToStorefront() {
  // Redirect to the URL
  window.location.href = land_nave_storefront_url;
  // Optionally reload the page after a short delay
  setTimeout(() => {
      window.location.reload(true);
  }, 1000);
}
  const logoutTracking =(flag) =>{
    if(localStorage.getItem('user_id')){
    axios.post(base_url+"login_tracking",{
      "user_id": localStorage.getItem('user_id') , "is_login": 0
    }).then(res=>{
      if(flag){
        // Call the function to redirect and reload
        redirectToStorefront();
        // window.open(land_nave_storefront_url, '_self')
        // window.location.reload()
      }else{
        history.push('/')
        window.location.reload()
      }
      clearLocalstroageAndReload()
      
    }).catch(err=>{
      if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/')
        window.location.reload();
      }else{
        toast.warn(api_error_message,{toastId:random_number});
      }
      console.log(err)
    })
    }else{
      clearLocalstroageAndReload()
      history.push('/')
      window.location.reload()
    }
  }
  const isbUserLogout=()=>{
    clearLocalstroageAndReload()
    history.push('/userlogin')
  }
  const onLogoOpen = () =>{
    window.open(storefront_url, '_self')
  }

const handleUserProfile=()=>{
  if(window.innerWidth < 993)
  {
    setModalShow(true)
  }
  else{
    history.push('/userprofile',{key:"profile"})
  }
}
const handleClose = () => setModalShow(false);

const jumpToDashboard=()=>{
  history.push('/maindashboard')
  handleClose()
}
const jumpToProfile=()=>{
  history.push('/userprofile',{key:"profile"})
  handleClose()
}

  return (
    <>
    
    <nav data-testid="nav_bar_main" id="top-nav-bar" className="navbar navbar-expand-lg navbar-light border-0 " style={{ backgroundColor:"white", width:"100%", padding:"0 20px 0 ", justifyContent:"space-between", zIndex:"99"}}>
     <img data-testid="isb_brand_logo" className='brand_logo cursor-pointer' src="/images/ISB_Online_logo.png" alt="" onClick={onLogoOpen} />
   
  {(window.location.pathname !=="/paymentdashboard" && localStorage.getItem('User_Accesstoken') && localStorage.getItem('isLoggedIn')=='true')  && <div className="collapse navbar-collapse nav_bar_contnts" id="navbarSupportedContent">
  <ul data-testid="nav_items" className="navbar-nav me-auto mb-2 mb-lg-0 m-auto" id="nav_bar_ul">
    <li className="nav-item d-none" >
    <Link data-testid="dashboard_nav" className="nav-link active" to="/maindashboard">Dashboard</Link>
    </li>
    {(localStorage.getItem('is_verizon_student') == 0||!localStorage.getItem('is_verizon_student'))  ? <li className="nav-item d-none">
      <a data-testid="learning_track_nav" className="nav-link active" aria-current="page" target="_blank" href={land_nave_storefront_url+'learning-tracks'}>Learning Tracks</a>
    </li>:''}
    <li className="nav-item d-none">
      <a className="nav-link active" aria-current="page" href="/pastcourse">Past Courses</a>
    </li>
    <li className="nav-item d-none">
      <a className="nav-link active" aria-current="page" href="/">Start New Course</a>
    </li>
    <li className="nav-item">
      <a className="nav-link active visually-hidden" aria-current="page" href="/projectmanagement">Project Management</a>
    </li>
    
    </ul>
</div>}
{
  decrypted_role==program_management_role ? <div>
    <h3 >ISB Online Program Management</h3>
  </div> :""
}

     <Nav.Item className="d-flex">
      <div>
      {
        localStorage.getItem('isLoggedIn') == 'true' && <button data-testid="logout_btn" className="btn btn-primary my_btn_cls signout" onClick={() => signOut()}>Logout</button>
      }
      </div>
      {
  decrypted_role==program_management_role ? <div className="d-flex align-items-center" data-testid="pm_notifications">
     <PmNotification />
  </div> :""
}
     {(window.location.pathname !=="/paymentdashboard" && localStorage.getItem('User_Accesstoken') && localStorage.getItem('isLoggedIn') == 'true') ? 
     (<div className="d-flex flex-row-reverse align-items-center">
     <div className="ms-3">
     <img data-testid="user_profile" className='user_avatar cursor-pointer' onClick={handleUserProfile} src="/images/avtar2.svg" alt="" />

     <Modal
        size="sm"
        show={showModal}
        className="navbar_Modal"
        data-testid="Modal_main"
        onHide={() => setModalShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body>
        <div data-testid="mobile_modal">
          <div className="Navbar_userModal mt-2">
          <div className="uname">
             <span data-testid="user_name_modal">{localStorage.getItem('User_Name')??"--"}</span>
          </div>
          <div className="uemail">
            <span data-testid="email_modal">{localStorage.getItem('User_Email')??"--"}</span>
          </div>
          {currentPath!=='/userprofile' && <>
        <div className="upath mt-4 mb-2 ml-2">
          <div data-testid="jumpToProfile_modal" onClick={jumpToProfile}>
            <div>
            <span>
           
               
             <img src="/images/mobile_profile.svg"></img>
                </span>
                 <span className="vertical-align-middle"> Profile</span> 
               </div>
            </div>
            </div>
          </>
          }
          {currentPath=='/maindashboard'&& currentPath!=='/userprofile' ?
          <hr></hr>:''}
            {currentPath !=='/maindashboard'&& currentPath !=='/userprofile' ?
          <hr></hr>:''}
          {currentPath!=='/maindashboard'&&<>
        <div className="upath mt-4 mb-2 ml-2">
          <div data-testid="jumpToDashboard_modal" onClick={jumpToDashboard}>
            <div>
                <span>
                  <svg className="Dashoard_icon vertical-align-middle" id="dashboard_icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 96 960 960" width="18"><path d="M510 486V216h330v270H510ZM120 606V216h330v390H120Zm390 330V546h330v390H510Zm-390 0V666h330v270H120Z"/></svg>
                </span>
                 <span className="vertical-align-middle"> Dashboard</span> 
               </div>
            </div>
            </div>
          </>
          }
          {currentPath !=='/maindashboard'&& currentPath=='/userprofile' ?
          <hr></hr>:''}
            {currentPath !=='/maindashboard'&& currentPath !=='/userprofile' ?
          <hr></hr>:''}
        <div>
        </div>
        <div className="usignout mt-4 mb-3 ml-2">
          <div data-testid="signout_modal" onClick={() => signOut()}>
          <span>
            <img className="vertical-align-middle" width={22} src="/images/Logout_Icon.svg" alt="" />
          </span>
          <span data-testid="logout_inmodal" className="vertical-align-middle">Logout</span>
          </div>
        </div>
          </div>
        </div>
        </Modal.Body>
      </Modal>
     </div>
     <div></div>
     <div>
     <Notification />
     </div>
      </div>)
      : '' }
     </Nav.Item>    
    </nav>
    </>
  );
}

export default LandNavbar;