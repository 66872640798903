import React, { useState, useEffect, Fragment } from "react";
import axios from "../axios-interceptor";
import { useHistory, useLocation } from "react-router-dom";
import { base_url, date_formats, Program_type, random_number, razorpay_key, api_error_message, land_nave_storefront_url, standalone_program_type, user_maintenance_type, downloadPdf } from "../utilities";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as solidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons';
import withMaintenanceCheck from "../HigherOrderComponents/maintenancePageChecker";
import withScreenSizeCheck from "../HigherOrderComponents/withScreenSizeCheck";
const MainDashboard = () => {
  let history = useHistory();
  let location = useLocation();
  let [curentProgrammes, setCurrentProgrammes] = useState([]);
  let [pastProgrammes, setPastProgrammes] = useState([]);
  let [futureProgrammes, setFutureProgrammes] = useState([]);
  let [currentLoadingFlag,setCurrentLoadingFlag] = useState(true)
  let [pastLoadingFlag,setPastLoadingFlag] = useState(true)
  let [futureLoadingFlag,setFutureLoadingFlag] = useState(true)
  const [isFavourite, setIsFavourite] = useState(false);
  let [key, setKey] = useState("current-programmes-tab");
  useEffect(() => {
    if (!localStorage.getItem("user_id")) {
      history.push('/')
    }
   }, []);
  useEffect(() => {
    if (!localStorage.getItem("user_id")) {
      history.push('/')
    }else{
    if (key == "current-programmes-tab") {
      setCurrentLoadingFlag(true)
      getCurrentProgrammes()
    } else if (key == "past-programmes-tab") {
      setPastLoadingFlag(true)
      getPastProgrammes()
    } else if (key == "future-programmes-tab") {
      setFutureLoadingFlag(true)
      getFutureProgrammes()
    }
  }
  }, [key]);
  const getCurrentProgrammes = () => {
    axios
      .post(base_url + "program_applications/fetch_learner_current_programmes", {
        user_id: localStorage.getItem("user_id"),
        email: localStorage.getItem("User_Email"),
      })
      .then((res) => {
          setCurrentLoadingFlag(false)
          if (res.data.data.length != 0) {
            setCurrentProgrammes(res.data.data)
          }
        
      }).catch((err) => {
        setCurrentLoadingFlag(false);
        if (err.response?.statusText == 'Unauthorized' || err.response?.data?.statusText == 'Unauthorized') {
          localStorage.clear();
          history.push('/')
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
  }
  const getPastProgrammes = () => {
    axios
      .post(base_url + "user/fetch_learner_past_programmes", {
        user_id: localStorage.getItem("user_id"),
      })
      .then((res) => {
        setPastLoadingFlag(false);
        if (res.data.result.length != 0) {
          setPastProgrammes(res.data.result)
        }
      }).catch((err) => {
        setPastLoadingFlag(false);
        if (err.response?.statusText == 'Unauthorized' || err.response?.data?.statusText == 'Unauthorized') {
          localStorage.clear();
          history.push('/')
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
  }
  const getFutureProgrammes = () => {
    axios
      .post(base_url + "program_applications/explore_other_programmes", {
        user_id: localStorage.getItem("user_id"),
        email: localStorage.getItem("User_Email"),
      })
      .then((res) => {
        setFutureLoadingFlag(false);
        if (res.data.data.length != 0) {
          setFutureProgrammes(res.data.data)
        }
      }).catch((err) => {
        setFutureLoadingFlag(false);
        if (err.response?.statusText == 'Unauthorized' || err.response?.data?.statusText == 'Unauthorized') {
          localStorage.clear();
          history.push('/')
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
  }

  //apply now button
  const handleApplyNow = (program_id, learningtrack_id) => {
    history.push(`/applynow_cta/${learningtrack_id}/${program_id}`)
  }
  //payment implementation
  const handlePayment = (program_id) => {
    localStorage.setItem('setPassword_UserId', localStorage.getItem('user_id'))
    localStorage.setItem('setPassword_programId', program_id)
    history.push("/addressapplicationform");
  };
  // pay now and pay next installment
  const handlePayNow = (program_id) => {
    localStorage.setItem('setPassword_UserId', localStorage.getItem('user_id'))
    localStorage.setItem('setPassword_programId', program_id)
    history.push('/emigateway')
  }
  // go to social login screen for lead form
  const handleFutureProgrammeApplyNow = (program_id, learningtrack_id) => {
    // to clear all cookies 
    document.cookie = "leadform_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "leadform_email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "leadform_mobile=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "leadform_ProgramId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "leadform_role=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "leadform_country_code=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "leadform_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    history.push(`/socialloginscreen/${learningtrack_id}/${program_id}`)
  }
  return (
    <>
      
        <div className="main-dashboard-container">
          <div className="dashboardResponsivePadding">
            <span className="welcome_text container mt-lg-5">
              My Dashboard
            </span>
            <div className="tab-container">
              <Tabs
                defaultActiveKey="current-programmes-tab"
                className="mb-5 main-dashboard-tabs"
                id="main-dashboard-tab"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="current-programmes-tab" title="My Current Programmes">
                {currentLoadingFlag ? <div>
        <img className="atrium_loader" src="images/atrium_loader.gif" alt="Loader" />
      </div> :
      <>{curentProgrammes.map((item, index) => {
                    return <>

                      {/* removing user application banned by admin by adding this condition item?.application_status != 3  */}
                      {item?.application_status != 3 && <>
                        <div className="card card-border dashboard_card  p-4 mb-5">
                          <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-12 dashboard_card_image_container">
                              <img className="w-100 rounded dashboard_card_image" src={item?.program_banner_image_url ? item.program_banner_image_url : './images/management_essentials.svg'}
                                alt="management_essentials"
                              />
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12 dashboard-content_card">
                              {/* user not applied yet just filled the lead form */}
                              {(item?.application_status == 4) &&
                                <>
                                  <span className="title-span">{item?.program_name}</span>
                                  <br /><br />
                                  <p className="paragraph-text">
                                    {item?.description ? item?.description : 'Effective management is essential for teams and organisations to drive successful business outcomes. This programme empowers you with a strong foundation in management principles required to create business impact. Through a distinctive pedagogical approach that emphasises diverse real-world examples, you will master the tools and techniques needed to excel in three key managerial capabilities: Executive Presence, Decision-making and Implementation.'}
                                  </p>
                                  <div>
                                    {item?.application_submission_end_date > date_formats.convert_current_date_utc_format(new Date()) ?
                                      <img src={"./images/Time Clock.svg"} alt="time-clock"></img> : <img src={"./images/red_tick.svg"} alt="time-clock"></img>}&nbsp;
                                    {item?.application_submission_end_date > date_formats.convert_current_date_utc_format(new Date()) ?
                                      <span className="blue_text time-span">Starts on {date_formats.human_date_format2(item?.program_start_date)}</span> : <span className="next_payment_red_color time-span">Application submission date is expired.</span>}

                                  </div>
                                  <button
                                    disabled={item?.application_submission_end_date < date_formats.convert_current_date_utc_format(new Date())}
                                    onClick={() => handleApplyNow(item?.program_id, item?.learning_track_id)}
                                    className="btn download-certificate-btn dashboard_button float-end dashboard_Btn_position bottom-0 end-0 px-5 mr-3 py-2"
                                  >
                                    Apply Now
                                  </button>
                                </>}
                              {/* user applied but not accepted yet */}
                              {(item?.application_status == 0 && item?.is_paid == 0) &&
                                <>
                                  <span className="status_span approval_blue_color">Approval is pending</span>
                                  <br></br>
                                  <span className="title-span">{item?.program_name}</span>
                                  <br />
                                  <p className="paragraph-text">
                                    Congratulations! Your application for the Master's in {item?.program_name} has been received and is currently being processed. Once your application is approved, you will receive a payment link.
                                  </p>
                                  <div>
                                    <img src="./images/Time Clock.svg" alt="time-clock"></img>&nbsp;
                                    <span className="blue_text time-span">Starts on {date_formats.human_date_format2(item?.program_start_date)}</span>
                                  </div>
                                  <button
                                    onClick={() => handlePayment(item?.program_id)}
                                    disabled={item?.application_status == 0}
                                    className="btn download-certificate-btn dashboard_button float-end dashboard_Btn_position bottom-0 end-0 px-3 mr-3 py-2"
                                  >
                                    Make payment
                                  </button>
                                </>}
                              {/* user applied and approved by admin */}
                              {((item?.application_status == 2 && item?.is_paid == 0) && (item?.payment_details_length == 0)) &&
                                <>
                                  {date_formats.convert_current_date_utc_format(new Date()) < item?.payment_date ? <span className="status_span approval_blue_color">Application Approved</span> : ''}
                                  <br></br>
                                  <span className="title-span">{item?.program_name}</span>
                                  <br />
                                  <p className="paragraph-text">
                                    Congratulations on your acceptance into our Masters Programme in {item?.program_name}! We are excited to have you as part of our community of students and look forward to helping you achieve your academic and professional goals.
                                    <br></br>
                                    <br></br>
                                    {date_formats.convert_current_date_utc_format(new Date()) < item.payment_date ? <>The programme will start on <span className="black_color">{date_formats.human_date_format2(item?.program_start_date)}</span>. To secure your seat in this programme, complete your payment before <span className="black_color">{date_formats.human_date_format2(item?.payment_date)}</span>. Follow the link to the payment gateway.</> : <>
                                      The programme started on <b>{date_formats.human_date_format2(item?.program_start_date)}</b><br></br>
                                      <img src={"./images/red_tick.svg"} alt="time-clock"></img>&nbsp;<span className="next_payment_red_color time-span">This programme is currently active.</span>
                                    </>}

                                  </p>

                                  <button
                                    onClick={() => handlePayment(item?.program_id)}
                                    // disabled={date_formats.convert_current_date_utc_format(new Date()) > item?.payment_date}
                                    className="btn download-certificate-btn dashboard_button float-end dashboard_Btn_position bottom-0 end-0 px-3 mr-3 py-2"
                                  >
                                    Make payment
                                  </button>
                                </>}
                              {/* user application rejected by admin */}
                              {(item?.application_status == 1 && item?.is_paid == 0) &&
                                <>
                                  <span className="status_span rejected_red_color">Application Rejected</span>
                                  <br></br>
                                  <span className="title-span">{item?.program_name}</span>
                                  <br />
                                  <p className="paragraph-text">
                                    Thank you for your application to our Certificate Masters Programme in {item?.program_name}. Unfortunately, we are unable to offer you a place in the programme at this time.
                                    <br></br>
                                    <br></br>
                                    We appreciate your interest and encourage you to apply again in the future. If you have any questions or need further information, please do not hesitate to reach out.

                                  </p>

                                </>}
                              {/* user payment done and fully paid */}
                              {((item?.application_status == 2 && item?.is_paid == 1) && (item?.payment_details_length == 0)) && <>
                                <span className="status_span payment_green_color">Full Payment</span>
                                <br></br>
                                <span className="title-span">{item?.program_name}</span>
                                <br />
                                <br />
                                <p className="paragraph-text">
                                  Congratulations on completing the full payment for the {item?.program_name} Program! The program begins on <span className="blue_text">{date_formats.human_date_format2(item?.program_start_date)}</span>. We are excited to have you as part of our community and look forward to supporting you in achieving your academic and professional goals.
                                </p>
                                <br></br>
                                <p className="pay_details_text mb-0">Paid Amount: <span className="black_color">{item?.full_payment_data?.amount_paid}</span></p>
                                <p className="pay_details_text mb-0">Paid on: <span className="payment_green_color"> {date_formats.human_week_date_format(item?.full_payment_data?.payment_date)}</span></p>
                                <button
                                  onClick={() => handlePayment(item?.program_id)}
                                  disabled={date_formats.convert_current_date_utc_format(new Date()) < item?.program_start_date}
                                  className="btn download-certificate-btn dashboard_button float-end dashboard_Btn_position bottom-0 end-0 px-3 mr-3 py-2"
                                >
                                  {item?.course_progress_status == 0 ? 'Start' : "Resume"} Programme
                                </button>
                              </>}
                              {/* user payment mode is installments (partial payments) and user is not paid yet */}
                              {((item?.application_status == 2) && (item?.payment_details_length != 0)) && <>
                                <span className="status_span payment_green_color">Partial Payment</span>
                                <br></br>
                                <span className="title-span">{item?.program_name}</span>
                                <br />
                                <br />
                                <p className="paragraph-text">
                                  Congratulations on making a partial payment for the {item?.program_name} program. The program starts on <span className="blue_text">{date_formats.human_date_format2(item?.program_start_date)}</span>. We are excited to have you as part of our community of students and look forward to helping you achieve your academic and professional goals.

                                </p>
                                <p className="pay_details_text mb-0">{item?.paid_installments[0]?.payment_name}: <span className="black_color">{item?.paid_installments[0]?.paid_amount}</span></p>
                                <div className="row ">
                                  {item?.paid_installments?.map((payment, index) => {
                                    if (index != 0)    // blocked fee is not shown as part of paid installments
                                      return (
                                        <>
                                          {index % 2 == 0 ? <>
                                            <div className="col-lg-5 pt-2  col-md-5 col-sm-12">
                                              <p className="pay_details_text mb-0">{payment?.payment_name}</p>
                                              <p className="pay_details_text mb-0">Paid Amount: <span className="black_color">{payment?.paid_amount}</span></p>
                                              <p className="pay_details_text mb-0">Paid on: <span className="payment_green_color">{date_formats.human_week_date_format(payment?.paid_date)}</span></p>
                                            </div>
                                          </> : <> <div className="col-lg-7 pt-2 col-md-5 col-sm-12">
                                            <p className="pay_details_text mb-0">{payment?.payment_name}</p>
                                            <p className="pay_details_text mb-0">Paid Amount: <span className="black_color">{payment?.paid_amount}</span></p>
                                            <p className="pay_details_text mb-0">Paid on: <span className="payment_green_color">{date_formats.human_week_date_format(payment?.paid_date)}</span></p>
                                          </div>
                                          </>}

                                        </>)
                                  })}
                                </div>
                                {item?.unpaid_installments.length > 0 && <>
                                  {item?.unpaid_installments[0]?.pay_by_date < date_formats.convert_current_date_utc_format(new Date()) ? <>
                                    <p className="pay_details_text next_payment_red_color mb-0">Your payment date has expired on {date_formats.human_date_format2(item?.unpaid_installments[0]?.pay_by_date)}</p>
                                    <p className="mb-0">Please message <span className="text-decoration-underline blue_text fw-bold cursor-pointer" onClick={() => { window.open('https://online.isb.edu/', '_blank') }}>ISB.Online.com</span> for any queries.</p>
                                    <p className="text-decoration-underline paragraph-text approval_blue_color cursor-pointer" onClick={() => handlePayNow(item?.program_id)}>Pay Now</p>
                                  </> : <>

                                    <p className="pay_details_text next_payment_red_color mb-0">Due date for Instalment {item?.paid_installments?.length} is {date_formats.human_date_format2(item?.unpaid_installments[0]?.pay_by_date)}</p>
                                    <p className="text-decoration-underline paragraph-text approval_blue_color cursor-pointer" onClick={() => handlePayNow(item?.program_id)}>Pay Next Installment</p>
                                  </>}
                                </>}


                                <button
                                  onClick={() => handlePayment(item?.program_id)}
                                  disabled={date_formats.convert_current_date_utc_format(new Date()) < item?.program_start_date || item?.unpaid_installments[0]?.pay_by_date < date_formats.convert_current_date_utc_format(new Date())}
                                  className="btn download-certificate-btn dashboard_button float-end dashboard_Btn_position bottom-0 end-0 px-3 mr-3 py-2"
                                >
                                  {item?.course_progress_status == 0 ? 'Start' : "Resume"} Programme
                                </button>
                              </>}
                            </div>
                          </div>
                        </div>
                      </>}
                    </>
                  })}</>}
                  




                </Tab>
                <Tab eventKey="past-programmes-tab" title="My Past Programmes">
                {pastLoadingFlag ? <div>
        <img className="atrium_loader" src="images/atrium_loader.gif" alt="Loader" />
      </div> :<>
                  {pastProgrammes.length != 0 ? <>
                    {pastProgrammes.map((item, index) => {
                      return <>
                        <div className="card card-border dashboard_card p-4 mb-5">
                          <div className="row">
                            <div className="col-lg-5  col-md-5 col-sm-12 dashboard_card_image_container mobile-screen-button">
                              <img className="w-100 rounded dashboard_card_image" src={item?.program_banner_image_url ? item.program_banner_image_url : './images/management_essentials.svg'}
                                alt="management_essentials"
                              />
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12">
                              <span className="title-span">{item?.program_name}</span>
                              <br></br>
                              {item?.courses_completed != item?.total_courses ? <span className="blue_text past_date_span">Expired on {date_formats.human_date_format2(item?.program_end_date)}</span> :
                                <span className="blue_text past_date_span">Completed on {item?.latest_module_completion_date ? date_formats.human_date_format2(item?.latest_module_completion_date) : ''}</span>}
                              <br />
                              <p className="paragraph-text">
                                {item?.description ? item?.description : 'Effective management is essential for teams and organisations to drive successful business outcomes. This programme empowers you with a strong foundation in management principles required to create business impact. Through a distinctive pedagogical approach that emphasises diverse real-world examples, you will master the tools and techniques needed to excel in three key managerial capabilities: Executive Presence, Decision-making and Implementation.'}
                              </p>
                              <div className="row mobile-row">
                                <div className="col-lg-3 col-md-3 col-sm-3 ">
                                  <div className="d-flex flex-start">
                                    <div className="mr-2">
                                      <img src="./images/badges_achieved.svg" alt="time-clock"></img>
                                    </div>
                                    <div className="stat-item">
                                      <div className="badge-content">
                                        <span className="badge-count">{item?.badges_earned}</span>
                                        <span className="badge-total">/{item?.total_badges}</span>
                                      </div>
                                      <span className="label">Badges Achieved</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-4  col-sm-4 ">
                                  <div className="d-flex flex-start">
                                    <div className="mr-2"> <img src="./images/total_time.svg" alt="time-clock">
                                    </img></div>
                                    <div className="stat-item">
                                      <div className="time">
                                        <span className="time-value">{item?.total_time_spent.match(/\d+/g).map(Number)[0]}</span><span className="time-text">Hour(s)</span><span className="time-value">{item?.total_time_spent.match(/\d+/g).map(Number)[1]}</span><span className="time-text">Min(s)</span>
                                      </div>
                                      <span className="label">Total Learning Time Spent</span>
                                    </div></div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 ">
                                  <div className="d-flex flex-start">
                                    <div className="mr-2"> <img src="./images/course_completed.svg" alt="time-clock"></img>
                                    </div>
                                    <div className="stat-item">
                                      <div className="courses">
                                        <span className="course-count">{item?.courses_completed}</span>
                                        <span className="course-total">/{item?.total_courses}</span>
                                      </div>
                                      <span className="label">Courses Completed</span>
                                    </div></div>
                                </div>
                              </div>
                              <button
                                disabled={item?.learning_track_certificate_url == ""}
                                className="btn download-certificate-btn dashboard_button float-end dashboard_Btn_position bottom-0 end-0 px-3 mr-3 py-2 mobile-screen-button"
                                onClick={() => downloadPdf(item?.program_distinction_certificate_url ? item.program_distinction_certificate_url : item?.learning_track_certificate_url)} download
                              >
                                Download Certificate
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    })}
                  </> : <>
                    <div className="text-center no-past-programmes">
                      <img className="mr-2" src="./images/no_past_programmes.svg" alt="no-past-programmes"></img>
                      <h4 className="text-center fw-bold mt-3 mb-2">No Past Programmes</h4>
                      <p className="text-center ash">It looks like there are no past programmes to show at the moment.<br></br> Please check back later for updates or explore our current and<br></br> upcoming programmes.</p>
                    </div>
                  </>}
                  </>}
                </Tab>
                <Tab eventKey="future-programmes-tab" title="Explore Other Programmes">
                {futureLoadingFlag ? <div>
        <img className="atrium_loader" src="images/atrium_loader.gif" alt="Loader" />
      </div> :<>
                  {futureProgrammes.map((item, index) => {
                    return <>
                      <div className="card card-border dashboard_card p-4 mb-5">
                        <div className="row">
                          <div className="col-lg-4  col-md-4 col-sm-12 dashboard_card_image_container mobile-screen-button">
                            <img className="w-100 rounded dashboard_card_image" src={item?.program_banner_image_url ? item.program_banner_image_url : './images/bussiness_strategy.svg'}
                              alt="business_strategy"
                            />
                            {/* <br></br> */}
                            {/* <button className="favourite-button" onClick={handleClick}>
                              <FontAwesomeIcon icon={isFavourite ? solidHeart : regularHeart} className="heart-icon" />
                              {isFavourite ? 'Added to Favourites' : 'Add to Favourites'}
                            </button> */}
                          </div>
                          <div className="col-lg-8 col-md-8 col-sm-12">
                            <span className="title-span pt-2">{item?.program_name}</span>
                            <br /><br />
                            <p className="paragraph-text">
                              {item?.description ? item.description : 'Optimal business strategies are complex to formulate and execute, especially in the face of potentially disruptive innovations and discontinuous change. This programme is designed to help you sharpen your strategic acumen, develop an innovative mindset, and equip you with the essential tools and knowledge to make the right decisions towards creating an effective strategy.'}
                            </p>
                            <div>{item?.application_submission_start_date > date_formats.convert_current_date_utc_format(new Date()) ? <>
                              <span className="blue_text time-span">New Cohort Will Start Soon</span>
                            </> : <>
                              <span className="blue_text time-span">Next Cohort Starts on {date_formats.human_date_format2(item?.program_start_date)}</span>
                              <br></br>
                              <span className="application-close-span">Applications Closes in {date_formats.days_difference(item?.application_submission_end_date)} Days</span>
                            </>}
                            </div>
                            {/* <button
                              className="btn dashboard_button float-end dashboard_Btn_position bottom-0 talk_to_advisor_btn px-3 mr-3 py-2"
                            >
                              Talk to Advisor
                            </button> */}
                            <button
                              disabled={item?.application_submission_start_date > date_formats.convert_current_date_utc_format(new Date())}
                              onClick={() => handleFutureProgrammeApplyNow(item?.program_id, item?.learning_track_id)}
                              className="btn download-certificate-btn dashboard_button float-end dashboard_Btn_position bottom-0 end-0 px-3 mr-3 py-2 mobile-screen-button"
                            >
                              Apply Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  })}
                  </>}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      
    </>
  )
}
export default withMaintenanceCheck(withScreenSizeCheck(MainDashboard), user_maintenance_type);